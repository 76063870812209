import request from './base'; //导入接口的前缀地址

//获取getPgs
export function getPgs(data) {
	return request({
		url: "/getPgs",
		method: "POST",
		data: data
	})
}
export function getPgspro(data) {
	return request({
		url: "/getPgProOhs",
		method: "POST",
		data: data
	})
}         

//获取getPps
export function getPps(data) {
	return request({
		url: "/getPps",
		method: "POST",
		data: data
	})
}

//获取getJls
export function getJls(data) {
	return request({
		url: "/getJls",
		method: "POST",
		data: data
	})
}

//获取getPgLive
export function getPgLive(data) {
	return request({
		url: "/getPgLive",
		method: "POST",
		data: data
	})
}

//获取getTadas
export function getTadas(data) {
	return request({
		url: "/getTadas",
		method: "POST",
		data: data
	})
}

//获取cq9
export function getCqs(data) {
	return request({
		url:"/getCqs",
		method:"POST",
		data:data
	})
}
