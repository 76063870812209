<template>
  <div class="dbox">
    <!-- <div class="left">
      <el-container>
        <CommonAside />
      </el-container>
    </div> -->
    <div class="right">
      <!-- <TopRes></TopRes> -->
      <div class="yhk_lb" 
        v-loading="loading" 
        element-loading-text="loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0)">
        <div class="yut_top">
          <router-link to="/rabkcard">
            <div class="xjyh_top" style="font-size: 15px;">
              <div>{{ $t("wdwal_rules_1") }}</div>
              <img src="../../assets/help.png" alt="" />
            </div>
          </router-link>
          <router-link to="/withdramoney">
            <div class="xjyh_top">
              <div>{{ $t("wdwal_detail_1") }}</div>
              <img src="../../assets/icon2 (1).png" alt="" />
            </div>
          </router-link>
        </div>
        <div class="jl_return">
          <img src="../../assets/back.png" style="width: 26px; height: 29px" alt="" />
          <div class="jl_zi">{{ $t('Withdraw') }}</div>
        </div>
        <div class="zf_tixian">
          <div class="tx_ziti">
            <div>{{ $t('funds') }}{{ this.totalcoin }}</div>
            <div>{{$t('cashmony')}}:{{ this.dcoin }}</div>
          </div>
          <div class="tx_rushu">
            <div>{{ $t('Withdraw') }}</div>
            <input v-model="goer.amount" type="number" placeholder="Retirada minima $0">
          </div>
          <div class="tx_zitiys">
            <p class="tip-info">
              <span>{{ $t('Withdraw') }}</span>
              <span class="value">{{ this.mylevel }}</span>
              <span style="margin-right: 0;">{{ $t('withdrawals') }}</span>
              <span class="value">1</span>
              <span>{{ $t('amount') }}</span>
              <span class="value">{{ this.daycoin }}</span>
            </p>
          </div>
        </div>
        <div class="yhk_nr" v-for="(item, index) in barNameList" :key="index" :data-current="index"
          @click="aaa(item.id, index)" :class="tabIndex == index ? 'tt' : ''">
          <img src="../../assets/qianbao.png" alt="">
          <div>{{ item.account }}</div>
          <div>{{ item.format_account }}</div>
          <div class="yhh">
            <div class="tr">
            </div>
          </div>
        </div>
        <div class="tx_anniu" @click="tjtx">{{ $t('Toremove') }}</div>{{ $t('Toremove') }}
      </div>
      <div></div>
      <el-container>
        <tabBar />
      </el-container>
    </div>
  </div>
</template>
  
<script>
// import CommonAside from "../../components/CommonAside";
// import TopRes from "../../components/TopRes.vue";
import tabBar from "../../components/tabBar.vue";
import { guide, drawconfig, getmoney } from '../../api/index.js'
import {doDraw} from '../../api/pay.js'
export default {
  components: {
    // CommonAside,
    // TopRes,
    tabBar,
  },
  data() {
    return {
      coin: '',
      dcoin: '',
      ecoin: '',
      totalcoin: '',
      barNameList: [],
      index1: 0,
      goer: {
        amount: '',
        bankid: ""
      },
      mylevel: '',
      daycoin: '',
      tabIndex: 0,
      loading: false,
    };
  },

  mounted() {
    this.yutt();
    this.ttyr();
    this.ioop()
  },

  methods: {
    tjtx() {
      this.loading = true;
      const data = {
        amount: this.goer.amount,
        bankid: this.goer.bankid
      }
      doDraw(data).then((res) => {
        this.loading = false;
        if (!res) return;
        if (res.code == 200) {
          this.$message({
            message: 'success',
            type: 'success'
          })
          window.location.reload();
        }
      })
    },

    yutt() {
      guide().then((res) => {
        if (!res) return;
        if (res.code != 200) return;
        this.barNameList = res.data
        if (res.data && res.data.length > 0)
          this.goer.bankid = res.data[0]['id']
      });
    },

    ttyr() {
      drawconfig().then((res1) => {
        this.mylevel = res1.data.mylevel
        this.daycoin = res1.data.daycoin
      });
    },

    ioop() {
      getmoney().then((res2) => {
        this.coin = res2.data.user.coin
        this.dcoin = res2.data.user.dcoin
        this.ecoin = res2.data.user.ecoin;
        this.totalcoin = res2.data.user.totalcoin;
      });
    },

    aaa(id, index) {
      this.tabIndex = index
      this.goer.bankid = id
    },
  }
};
</script>
  
<style scoped>
.tip-info {
  margin: 6px 0;
}

.tip-info span {
  margin-right: 5px;
}

.tip-info .value {
  margin-right: 15px;
  color: gold;
}

.sf_box {
  width: 80%;
  height: 650px;
  background-repeat: no-repeat;
  /* background-size: cover; */
  /* background-position: center; */
  background-repeat: repeat;
  /* 2. repeat-x 表示 x轴铺满 */
  background-repeat: repeat-x;
  /* 3. repeat-y 表示 y轴铺满 */
  background-size: 100% 650px;
  margin: 70px auto;
}

.yhk_lb {
  width: 70%;
  /* margin-left: 10%; */
  margin: 0 auto;
  margin-top: 60px;
  height: auto;
}

.yhk_fh {
  width: 100px;
  height: 40px;
  font-size: 30px;
  color: #fff;
}

.yhk_nr {
  width: 90%;
  height: 50px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, .1);
  border-radius: 10px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  padding: 12px 0;
}

.yhk_nr img {
  width: 40px;
  height: 40px;
}

.zf_tixian {
  width: 90%;
  /* height: 100px; */
  margin: 0 auto;
}

.tx_ziti {
  width: 100%;
  height: 20px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.tx_rushu {
  width: 100%;
  height: 40px;
  color: #fff;
  display: flex;
  margin-top: 20px;
  border: 1px solid white;
  line-height: 40px;
}

.tx_rushu div {
  width: 50%;
  height: 40px;
  line-height: 40px;
  margin-left: 20px;
}

.tx_rushu input {
  width: 50%;
  height: 38px;
  background-color: rgb(15, 14, 14);
  border: 0;
  color: #fff;
}

.tx_zitiys {
  display: flex;
  color: #fff;
  margin-top: 10px;
}

.tx_anniu {
  width: 200px;
  height: 40px;
  margin: 0 auto;
  background-color: brown;
  color: #fff;
  line-height: 40px;
  text-align: center;
  margin-top: 30px;
  border-radius: 10px;
  cursor: pointer;
}

.tr {
  width: 40px;
  height: 40px;
  margin-top: 10px;
  background: url(../../assets/dgou.9a5e3b81.png) no-repeat;
  background-size: 40px 40px;
}

.tt .tr {
  background: url(../../assets/duigo.6f65240e.png) no-repeat;
  background-size: 40px 40px;
}

.yut_top {
  display: flex;
  justify-content: right
}

.yut_top a {
  width: 280px;
  display: block;
  background: rgba(255, 255, 255, 0.118);
  margin: 0 20px 0 0;
  color: #fff;
  text-decoration: none;
  border-radius: 10px;
  padding: 10px 0
}

.xjyh_top {
  display: flex;
  align-items: center;
  text-align: center;
}

.xjyh_top div {
  margin-left: 30px;
  margin-right: 40px;
}

.xjyh_top img {
  width: 30px;
  height: 30px;
}

.jl_return {
  width: 100%;
  clear: both;
  display: flex;
  align-items: center;
  color: #fff;
}</style>
  