<template>
  <div class="dbox">
    <!-- <div class="left">
      <el-container>
        <CommonAside />
      </el-container>
    </div> -->
    <div class="right">
      <!-- <TopRes></TopRes> -->
      <div class="top">
        <div class="ziti">{{ $t('ranking') }}</div>
        <div class="as">{{ $t('updated') }}</div>
        <div class="os">{{ $t('subscription') }}</div>
        <div class="cli">{{ $t('participate') }} </div>

        <div class="grzx">
          <div class="g1">
            <img class="tx" :src="avatar" style="width: 60px; height: 60px;" alt="">
            <div class="zf_f">
              <div class="zf_f1">{{ playername }}</div>
              <div class="zf_f2">ID:{{ uid }}</div>
            </div>
          </div>
          <div class="g2">
            <div class="zf_zi">{{ $t('FortuneClassification') }}</div>
            <div class="zf_zi1">{{ $t('NoRanking') }}</div>
          </div>
        </div>
      </div>
      <div class="list-box">
        <div class="phb_top">
          <span class="phb_top1">{{ $t('Ranking') }}</span>
          <span class="phb_top1">{{ $t('id') }}</span>
          <span class="phb_top1">{{ $t('Textfone') }}</span>
          <span class="phb_top1">{{ $t('Currency') }}</span>
        </div>
        <div class="phb_tab" v-for="(item, index) in barNameList" :key="index" :data-current="index"
          @click="ontabtap(item.id)">
          <div class="phb_tab1">
            {{ index + 1 }}
          </div>
          <div class="phb_tab2">
            <!-- <img :src="item.avatar" style="width: 35px; height: 35px; margin-top: 5px;" alt=""> -->
            <div>{{ item.playername }}</div>
          </div>
          <div class="phb_tab1">{{ item.uid }}</div>
          <div class="phb_tab1">{{ $t('mouy') }}:{{ item.coin }}</div>
        </div>
      </div>
      <el-container>
        <tabBar />
      </el-container>
    </div>
  </div>
</template>

<script>
// import CommonAside from '../../components/CommonAside'
// import TopRes from '../../components/TopRes.vue'
import tabBar from '../../components/tabBar.vue';
import { index, member } from "../../api/index.js";
export default {
  data() {
    return {
      barNameList: [],
      avatar: '',
      playername: '',
      uid: ''
    };
  },
  
  methods: {
    phbxr() {
      index().then((res) => {
        if (res.code == 200) {
          console.log(res);
          this.barNameList = res.data.ranks
        }
      })
      member().then((res1) => {
        console.log(res1);
        if (res1) {
          if (res1.code == 200) {
            localStorage.setItem("userinfo", JSON.stringify(res1.data));
            this.avatar = res1.data.avatar;
            this.playername = res1.data.user.playername;
            this.uid = res1.data.user.uid;
            // 将对象转为 JSON 字符串
            // const jsonString = JSON.stringify(userObject);
          }
        }
      });
    },

    ontabtap(id) {

    },
  },

  mounted() {
    this.phbxr()
  },

  components: {
    // CommonAside,
    // TopRes,
    tabBar
  }
}
</script>

<style scoped>
.list-box {
  width: 70%;
  /* height: 800px; */
  min-height: 500px;
  margin:20px auto 0 auto; 
  margin-top: 40px; 
  border: 2px solid pink; 
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.box {
  width: 101%;
  height: 903px;
  background-color: rgb(15, 14, 14);
  overflow-x: scroll;
}

.top {
  /* width: 80%; */
  width: 90%;
  /* height: 200px; */
  background-image: url(../../assets/rankBgc.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  margin-top: 60px;
}

.ziti {
  color: #fff;
  font-size: 44px;
  padding-top: 60px;
  text-align: center;
}

.phb_top {
  color: #fff;
  /* width: 90%; */
  width: 100%;
  height: 100px;
  /* margin: 0 auto;
  margin-left: 5%; */
  text-align: center;
  line-height: 100px;
  display: flex;
  justify-content: space-between;
}

.phb_tab {
  display: flex;
  /* width: 90%; */
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  justify-content: space-between;
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-radius: 15px;
  /* margin-left: 5%;
  margin-top: 20px; */
}

.as {
  color: #fff;
  font-size: 24px;
  margin-top: 10px;
  text-align: center;
}

.os {
  width: 600px;
  height: 50px;
  color: #fff;
  font-size: 24px;
  margin: 0 auto;
  margin-top: 50px;
  text-align: center;
}

.cli {
  color: #fdf904;
  font-size: 28px;
  margin-top: 60px;
  text-align: center;
}

.phb_top1 {
  /* width: 25%;
  height: 200px;
  margin: 0 auto; */
  flex: 1;
}

.phb_tab1 {
  /* width: 25%;
  height: 200px;
  margin: 0 auto; */
  flex: 1;
}

.phb_tab2 {
  /* width: 25%;
  height: 50px;
  margin: 0 auto; */
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.an {
  width: 600px;
  height: 50px;
  display: flex;
  margin: 0 auto;
  margin-top: 20px;
}

.iop {
  width: 120px;
  height: 50px;
  background-color: rgb(122, 62, 49);
  line-height: 50px;
  border-radius: 25px;
  color: #fff;
  margin-left: 50px;
  text-align: center;
}

.grzx {
  width: 80%;
  height: 100px;
  background-color: black;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 15px;
  display: flex;
}

.g1 {
  width: 33%;
  height: 100px;
  display: flex;
}

.tx {
  margin-top: 20px;
  margin-left: 80px;
  text-align: center;
}

.zf_f {
  color: #fff;
  margin-left: 10px;
}

.zf_f1 {
  height: 20px;
  font-size: 22px;
  margin-top: 22px;
}

.zf_f2 {
  margin-top: 15px;
}

.g2 {
  width: 33%;
  height: 100px;
  background: url(../../assets/fly.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
}

.zf_zi {
  text-align: center;
  margin-top: 15px;
  font-size: 12px;
}

.zf_zi1 {
  text-align: center;
  margin-top: 10px;
  font-size: 24px;
  color: gold;
}

.g3 {
  width: 33%;
  height: 100px;
  color: #fff;
  text-align: center;
  margin-top: 30px;
}

.uui {
  font-size: 12px;
}

.uui1 {
  font-size: 28px;
}
</style>
