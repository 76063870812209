<template>
  <div class="dbox">
    <!-- <div class="left">
      <el-container>
        <CommonAside />
      </el-container>
    </div> -->
    <div class="right loading-box" 
      v-loading="loading" 
      element-loading-text="loading..." 
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0)">
      <!-- <TopRes></TopRes> -->
      <div class="yq_box3">
        <div class="znx_ziti">Receba sua recompensa</div>
        <!-- <div class="znx_nr_box">
          <div class="znx_nr_ziti">{{ msg }}</div>
          <div class="znx_nr_sj">
            <div>Remeber: System</div>
            <div>{{ timestamp }}</div>
          </div>
        </div> -->
        <img src="../../assets/content-img-bg.png" />
        <div class="znx_anniu">
          <div style="display: flex;">
            <div>Correio:</div>
            <div class="znx_nr4">
              <div class="yutr">
                <img src="../../assets/jb (2).png" alt="" />
                <div>+{{ eInfo ? eInfo.attach[1] : 0 }}</div>
              </div>
            </div>
          </div>
          <!-- <div class="zf_tyer">
            <div>Receber</div>
          </div> -->
          <div class="btn-box">
            <el-button class="btn" 
              :disabled="loading || (!eInfo || eInfo.hastake ? true : false)" 
              @click="receiveAttach">Receber</el-button>
          </div>
        </div>
      </div>
      <tabBar />
    </div>
  </div>
</template>

<script>
// import CommonAside from "../../components/CommonAside";
// import TopRes from "../../components/TopRes.vue";
import tabBar from "../../components/tabBar.vue";
import { emailInfo, getAttach } from '../../api/index.js'
export default {
  data() {
    return {
      // msg: "",
      // timestamp: '',
      // gold: '',
      eInfo: '',
      usid: {
        id: ''
      },
      loading: false,
    };
  },

  methods: {
    ytu(id) {
      emailInfo(id).then((res) => {
        // this.gold = res.data.info.attach[1];
        // this.timestamp = res.data.info.timestamp;
        // this.msg = res.data.info.msg;
        this.eInfo = res.data.info;
      })
    },

    // 领取站内信奖励
    receiveAttach() {
      this.loading = true;
      getAttach({
        id: this.eInfo.id
      }).then((res) => {
          this.loading = false;
          if (!res) return;
          this.$message({
            message: res.message,
            type: res.code == 200 ? 'success' : 'error'
          });
          if (res.code != 200) return;
          // this.ytu({ id: this.id });
          window.location.reload();
      });
    },
  },

  mounted() {
    this.id = this.$route.query.id;
    this.ytu({ id: this.id });
  },

  components: {
    // CommonAside,
    // TopRes,
    tabBar,
  },
};
</script>
  
<style scoped>
.right {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}

.yq_box3 {
  /* width: 80%;
  height: 700px; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* background-position: center; */
  /* background-repeat: repeat; */
  /* 2. repeat-x 表示 x轴铺满 */
  /* background-repeat: repeat-x; */
  /* 3. repeat-y 表示 y轴铺满 */
  /* background-size: 100% 600px; */
  /* margin-top: 70px; */
}

.znx_nr_box {
  width: 70%;
  height: 600px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, .1);
  border-radius: 10px;
  padding-bottom: 10px;
  padding-inline: 10px;
}

.znx_ziti {
  color: #fff;
  font-size: 20px;
  text-align: center;
  height: 30px;
  margin-bottom: 10px;
}

.znx_nr_ziti {
  width: 90%;
  height: 90%;
  margin: 0 auto;
  color: #fff;
  padding-top: 20px;
  font-size: 18px;
  word-wrap: break-word;
}

.znx_nr_sj {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.znx_anniu {
  /* width: 70%; */
  /* height: 100px; */
  color: #fff;
  /* margin: 0 auto; */
  width: 100%;
  margin-top: 24px;
}

.znx_nr4 {
  display: flex;
  margin-left: 10px;
}

.zf_tyer {
  width: 200px;
  height: 40px;
  background-color: rgb(104, 52, 22);
  margin: 0 auto;
  margin-top: 45px;
  line-height: 40px;
  border-radius: 10px;
}

.yutr {
  display: flex;
}

.zf_tyer div {
  text-align: center;
  cursor: pointer;
}

.btn-box {
  width: 200px;
  height: 40px;
  margin: 0 auto;
  margin-top: 45px;
  position: relative;
}

.btn-box .btn {
  width: 100%;
  height: 100%;
  border: none;
  background-color: rgb(104, 52, 22);
  color: #fff;
  font-size: 16px;
  border-radius: 12px;
}

.btn-box .el-button.is-disabled, 
.btn-box .el-button.is-disabled:focus, 
.btn-box .el-button.is-disabled:hover {
    color: #fff;
    background-color: #616060;
    border-color: unset;
}
</style>
  