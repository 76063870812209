<template>
  <div class="dbox">
    <!-- <div class="left">
      <el-container>
        <CommonAside />
      </el-container>
    </div> -->
    <div class="right">
      <!-- <TopRes></TopRes> -->
      <div class="yq_box">
        <div style="width: 100%; height: 50px; margin: 0 auto;">
          <button
            style="width: 350px; height: 70px; background-color: brown; margin-left: 35%; border-radius: 10px; border: 1px solid rgb(105, 48, 22); color: #fff; font-size: 20px; margin-top: 500px; ">{{
              $t('Contactnow') }}</button>
        </div>
      </div>
      <tabBar />
    </div>
  </div>
</template>
  
<script>
// import CommonAside from '../../components/CommonAside'
// import TopRes from '../../components/TopRes.vue'
import tabBar from '../../components/tabBar.vue';
export default {
  components: {
    // CommonAside,
    // TopRes,
    tabBar
  }
}
</script>
  
<style scoped>
.yq_box {
  width: 80%;
  height: 600px;
  background-image: url(../../assets/agent.png);
  background-repeat: no-repeat;
  /* background-size: cover; */
  /* background-position: center; */
  background-repeat: repeat;
  /* 2. repeat-x 表示 x轴铺满 */
  background-repeat: repeat-x;
  /* 3. repeat-y 表示 y轴铺满 */
  background-size: 100% 600px;
  margin-top: 70px;
}
</style>
  