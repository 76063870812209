
<template>
    <div class="frame">
      <div class="side-menu" :style="{ width: sideMenuWidth + 'px' }">
        <CommonAside @expand="handleExpand" />
      </div>
      <div class="content-box">
        <TopRes />
        <router-view />
      </div>
    </div>
  </template>

  <script>
  import CommonAside from '@/components/CommonAside.vue';
  import TopRes from '@/components/TopRes.vue';

  export default {
    components: {
      CommonAside,
      TopRes,
    },

    data() {
      return {
        sideMenuWidth: 250,
      };
    },

    mounted() {

    },

    methods: {
      handleExpand(expand) {
        this.sideMenuWidth = expand ? 250 : 70;
      }
    },
  }
  </script>

  <style scoped>
    .frame {
      width: 100%;
      height: 100%;
      display: flex;
      background-color: antiquewhite;
    }

    .side-menu {
      /* width: 250px; */
      transition: width 300ms;
    }

    .content-box {
      flex: 1;
      margin-top: 70px;
      background-color:#0B2E21;
      overflow-y: auto;
    }
  </style>
