<template>
  <div class="dbox">
    <!-- <div class="left">
      <el-container>
        <CommonAside />
      </el-container>
    </div> -->
    <div class="right">
      <!-- <TopRes></TopRes> -->
      <div class="vip_box">
        <div class="vip_dj">
          <div class="vip_yt" style="margin-top: 70px;">
            <img src="../../assets/0.png" style="width: 200px; height: 110px;" alt="">
            <div class="vip_uyt">
              <img src="../../assets/VIP.png" style="width: 80px; height: 30px; " alt="">
              <span>{{ mylevel }}</span>
            </div>
          </div>
          <div class="vip_nr">
            <div style="width: 100%; height: 60%; display: flex;">
              <div class="vip_zn">
                <div style="font-size: 30px; margin-top: 20px;">{{ $t('Privilege') }}</div>
                <div style="margin-top: 20px;  font-size: 18px;">{{ $t('Maximum') }}{{ daycoin }}</div>
                <div style="margin-top: 20px;  font-size: 18px;">{{ $t('withdrawals') }}{{ times }}</div>
              </div>
            </div>
            <div class="vip_xt" style="width: 100%; height: 40%;">
              <div style="margin-top: 70px; height: 50%;">
                <span style="color: #fff;">{{ $t('deposits') }}</span>
                <div
                  style="width: 85%; background-color:rgba(255, 255, 255, .1); display: flex; color: #fff; border-radius: 30px; font-size: 12px; margin-top: 10px;">
                  <div style="width: 33%; padding: 5px 12px;">{{ mylevelname }}</div>
                  <div style="width: 33%; padding: 5px 12px; text-align: center;">{{ mysvipexp }}/{{ nextsvipexp }}</div>
                  <div style="width: 33%; padding: 5px 12px; text-align: right;">{{ nextlevelname }}</div>
                </div>
              </div>
              <div style="margin-top: 30px; height: 50%;">
                <span style="color: #fff;">{{ $t('Betting') }}</span>
                <div
                  style="width: 85%; background-color:rgba(255, 255, 255, .1); display: flex; color: #fff; border-radius: 30px; font-size: 12px; margin-top: 10px;">
                  <div style="width: 33%; padding: 5px 12px;">VIP{{ level }}</div>
                  <!-- <div style="width: 33%; padding: 5px 12px; text-align: center;">{{ mytotalbet }}/{{ nexttotalbet }}</div> -->
                  <div style="width: 33%; padding: 5px 12px; text-align: right;">VIP{{ next_level }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vip_wio" v-for="(item, index) in barNameList" :key="index" :data-current="index"
          @click="ontabtap(item.id)">
          <div class="vip_yt" style="width: 20%; height: 100%; margin-top: 20px;">
            <img src="../../assets/0.png" style="width: 200px; height: 110px;" alt="">
            <div class="vip_uyt">
              <img src="../../assets/VIP.png" style="width: 80px; height: 30px; " alt="">
              <span>{{ item.level }}</span>
            </div>
          </div>
          <div style="width: 80%; height: 100%; display: flex;">
            <div class="vip_zn">
              <div style="font-size: 30px; margin-top: 20px;">{{ $t('Privilege') }}</div>
              <div style="margin-top: 20px;  font-size: 18px;">{{ $t('Maximum') }}{{ item.drawlimit.daycoin }}</div>
              <div style="margin-top: 20px;  font-size: 18px;">{{ $t('withdrawals') }}{{ item.drawlimit.times }}</div>
            </div>

          </div>
        </div>
      </div>
      <el-container>
        <tabBar />
      </el-container>
    </div>
  </div>
</template>

<script>
// import CommonAside from '../../components/CommonAside'
// import TopRes from '../../components/TopRes.vue'
import tabBar from '../../components/tabBar.vue'
import { vip } from "../../api/index.js";
export default {
  data() {
    return {
      daycoin: '',
      times: '',
      mylevel: 0,
      mylevelname: '',
      mysvipexp: 0,
      nextlevelname: 0,
      nextsvipexp: 0,
      mytotalbet: 0,
      nexttotalbet: 0,
      level: 0,
      next_level: '',
      barNameList: []
    };
  },
  methods: {
    ymxr() {
      vip()
        .then((res) => {
          console.log("++++++++++++++++++++++++++++",res);
          if (res) {
              if (res.data.error=="token失效") {
            return
          }else{
            this.daycoin = res.data.exp.daycoin;
          this.times = res.data.exp.times;
          this.mylevel = res.data.exp.mylevel;
          this.mylevelname = res.data.exp.mylevelname;
          this.mysvipexp = res.data.exp.mysvipexp;
          this.nextlevelname = res.data.exp.nextlevelname;
          this.nextsvipexp = res.data.exp.nextsvipexp;
          this.mytotalbet = res.data.exp.mytotalbet;
          this.nexttotalbet = res.data.exp.nexttotalbet;
          this.level = res.data.exp.level;
          this.next_level = res.data.exp.next_level;
          this.barNameList = res.data.vipList;
          }
          }
        
          
        })
    }
  },
  mounted() {
    this.ymxr()
  },
  components: {
    // CommonAside,
    // TopRes,
    tabBar,
  }
}
</script>

<style scoped>
.box {
  width: 100%;
  height: 903px;
  background-color: rgb(15, 14, 14);
}

.vip_box {
  width: 80%;
  height: 903px;
  background-color: rgba(255, 255, 255, .1);
  margin-top: 70px;
  border-radius: 20px;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 70px auto 0px auto;
}

.vip_dj {
  width: 95%;
  height: 350px;
  margin: 0 auto;
  background-image: url(../../assets/vipbgace.png);
  background-repeat: no-repeat;
  /* background-size: cover; */
  /* background-position: center; */
  background-repeat: repeat;
  /* 2. repeat-x 表示 x轴铺满 */
  background-repeat: repeat-x;
  /* 3. repeat-y 表示 y轴铺满 */
  border-radius: 30px 30px 30px 30px;
  display: flex;
  background-position: 0px 0px;
  border: 1px solid #fff;
  background-size: 100% 350px;
}

.vip_yt {
  width: 20%;
  height: 350px;
  text-align: center;

}

.vip_nr {
  width: 80%;
  height: 60%;

}

.vip_uyt span {
  color: #f7d206;
  font-size: 40px;
  margin-left: 10px;
  margin-top: -10px;
}

.vip_zn {
  width: 50%;
  height: 100%;
  color: #ffe51f;
}

.vip_yn {
  width: 50%;
  height: 100%;
  color: #16ddff;
}

.vip_wio {
  width: 95%;
  height: 200px;
  margin: 0 auto;
  margin-top: 30px;
  background-color: rgba(255, 2555, 255, 0.05);
  border-radius: 20px;
  display: flex;
  border: 1px solid #fff;
}</style>
