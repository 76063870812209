<template>
  <div class="tad_box" >
   <!-- <div style="width:15%; height:300px; ">
     <div style="color: #fff; font-size: 30px; margin-top: 10px;">Jogo</div>
     <div style="color: #fff; margin-top: 10px;">{{ $t('Hall') }}</div>
     <div style="color: #fff; margin-top: 10px;">PG-Slots</div>
     <div style="color: #fff; margin-top: 10px;">JILI-Slots</div>
     <div style="color: #fff; margin-top: 10px;">PP-Slots</div>
     <div style="color: #fff; margin-top: 10px;">vivo</div>
     <div style="color: #fff; margin-top: 10px;">viver</div>
   </div> -->
   <!-- <div style="width: 15%; height: 300px; ">
    <div style="color: #fff; font-size: 30px; margin-top: 10px;">Ajuda</div>
    <div style="color: #fff; margin-top: 10px;">Politica de Privacidade</div>
    <div style="color: #fff; margin-top: 10px;">Termos de Servico</div>
    <div style="color: #fff; margin-top: 10px;">Descrico do nivel VIP</div>
   </div> -->
   <!-- <div style="width: 70%; height: 100%;"> -->
  <div class="bar-box">
   <div style="width: 100%; display: flex;margin-top: 10px;">
    <img src="../assets/logo2.7b35e1e5.png" style="width: 140px; height: 40px; " alt="">
    <!-- <div style="margin-top: 10px;"></div> -->
   </div> 
   <p style="width: 100%; height: 30px;line-height: 24px; margin-top: 6px;">
    {{ $t('competencia') }}
   </p>
   <div class="footer22">
    <img src="../assets/footer1.png" >
    <img src="../assets/footer2.png" >
    <img src="../assets/footer3.png" >
    <img src="../assets/footer4.png" >
    <img src="../assets/footer5.png" >
    <img src="../assets/footer6.png" >
   </div>
   <div class="footer23"><img src="../assets/footer7.png" alt=""></div>
  </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.tab{
  width:80%;
  margin-left:100px;
}
.tad_box{
  /* width: 78%; */
  width: 100%;
  height: 300px;
  color: #fff;
  /* background-color: #000; */
  /* margin:0 0 0 50px; */
  border-radius: 15px;
  display: flex;
  justify-content: center;
  margin-top: 62px;
}
.bar-box {
  width: 80%; 
  height: 100%;
}
.footer22{
  width:100%;
  margin-top:100px;
  display: flex;
  justify-content: center;
}
.footer22 img{
  width:140px;
  height:53px;
}
.footer23 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.footer23 img {
  width: 50%; 
  height: 30px; 
  margin-top: 20px; 
  margin: 20 auto; 
}
</style>