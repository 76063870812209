import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import VueI18n from 'vue-i18n'
import es from "./lang/es/es"
import pt from "./lang/pt/pt"

import VueLazyload from 'vue-lazyload'

Vue.use(VueI18n);   // 全局注册国际化包
 
// 准备翻译的语言环境信息
const i18n = new VueI18n({
  locale: "pt",   // 初始化葡萄牙
  messages: {
    "es":es,
    "pt":pt
  }
}); 

// 设置懒加载
Vue.use(VueLazyload, {
  preLoad: 1.3, //预加载的宽高比
  loading: require('assets/img_loading.gif'), //图片加载状态下显示的图片
  // error: errorimage, //图片加载失败时显示的图片
  attempt: 1, // 加载错误后最大尝试次数
})
 
Vue.use(ElementUI)

Vue.config.productionTip = false

Vue.prototype.$axios = axios;

// 引入Element-ui
import { Button } from 'element-ui';
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 };
Vue.use(Button);

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
