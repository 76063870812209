
// 葡萄牙语言
export default {
    Firstcharge: "Primeira cobrança",
    ReloadBonuses: 'Bônus de recarga',
    Becomeapartner: 'Torne-se um parceiro',
    joinus: 'Junte-se a nós',
    InvitationBonus: 'Bônus de convite',
    VIPIntroduction: 'Introdução VIP',
    Personalranking: 'Ranking pessoal',
    Contactus: 'Correspondência',
    customerservice: 'atendimento ao Cliente',
    Rechargepresent: 'Presente de recarga',
    Contactnow: 'Contate agora',
    Jointhegroup: 'Juntar-se ao grupo',
    InvitefriendsEarnmoneytogether: 'Convide amigos Ganhar dinheiro juntos',
    promote: 'Para divulgar a plataforma diamante5, lancado um plano especialderecompensa por convite, para que todos possam ganhar grandes premiosnojogo imersivo e.',
    Howtoinvite: 'Como convidar',
    Invitedaily: 'Convite diariamente',
    Invitationdetails: 'Dados do convite',
    tellmore: ' Divulgar mais amigos esta excelenteplataforma de jogo por meio depromocao social e ganhar muito dedinheiro ao mesmo tempo comissao.',
    invitationlink: 'Clique no botão para copiar o link do convite',
    copy: 'copia',
    registration: 'Fornea convites válidos aos clientes diamante5 (os clientes devem preencher o cadastro do número do celular e completar a recarga) e a recompensa máxima pode ser de R$ 60,00. Ao mesmo tempo, realizamos contra-comisses com base na quantidade de apostas que os utilizadores fazem na plataforma de jogo (as comisses so consideráveis). As taxas de comisso variam de jogo para jogo.',
    Forexample: 'Por exemplo:',
    betcacau: 'Tom se registrou como usuário válido do diamante5 em 10 de abril de 2023. Ao ganhar o prêmio de plataforma, ele também recomendou um colega da empresa. O colega completou o cadastro e recarregou 50 reais, e Tom recebeu uma recompensa de 10,00 reais. Este colega gosta de jogos de azar. Total de apostas por 3 dias consecutivos. Ao longo de abril, Tom faturou um total de R$ 51.089 em comisses. De acordo com a política de descontos do diamante5, você ganha uma comisso adicional de R$ 2.670,17. Embora este colega tenha recomendado diamante5a ao seu amigo, de acordo com a política de descontos do diamante5, Tom também gostou da política de descontos de apostas relevante do amigo do seu colega.',
    month: 'mês',
    today: 'hoje',
    week: 'semana',
    year: 'ano',
    yesterday: 'ontem',
    Myagentdetails: 'Meus dados do agente',
    Number: 'Número',
    Codevalue: 'Valor do código',
    Fullrecharge: 'Recarga total',
    Initialcharge: 'Carga inicial',
    LevelAgent: 'Agente nível 1',
    Codevalue: 'Valor do código',
    Level2Agent: 'Agente nível 2',
    Level1Promotion: 'Promoçãonível1',
    Promotion: 'Promoçãonível2',
    alcum: 'alcume',
    Fullbet: 'Aposto total',
    Loadmore: 'Carregar mais',
    Privilege: 'Privilégio',
    Maximum: 'Limite máximo de retirada única:R$ ',
    // withdrawals: 'Número de retiradas por dia:$',
    Betting: "Pontos de apostas:",
    deposits: "Depositos totais:",
    ranking: 'Classificacao semanal de apostas',
    updated: '(As classificações são atualizadas a cada hora)',
    subscription: 'Os dez principais usuários diários ingressam no grupo de assinatura para receber recompensas de classificaçã',
    participate: 'Clique para participar',
    FortuneClassification: 'Classificação da Fortuna',
    NoRanking: 'Sem classificação',
    Ranking: 'Classificação',
    id: "EU IA",
    Textfone: 'Telefone de texto',
    Currency: 'Moeda(R$)',
    reward: 'Receba sua recompensa',
    more: 'Carregue mais',
    Withdraw: 'Retirar',
    funds: 'fundos totais:',
    withdrawals: 'O número máximo de saques por dia é ',
    amount: 'e o valor máximo de retirada é',
    Toremove: 'Retirar',
    deposit_1: 'Depósito',
    choose_pay_1: 'Escolha o metodo de pagamento',
    fast_pay_1: 'Canal de pagamento rápido 1',
    identifier_1: 'identificador',
    value_1: 'Valor',
    time_1: 'tempo',
    next_level_1: 'Próximo nível',
    total_dep_1: 'Depósitos totais',
    withdrawal_1: 'Retirada',
    play_amount_1: 'Jogue para desbloquear maisquantia extraivel',
    removable_1: 'Retirável',
    value_unlocked_1: 'Valor a ser desbloqueado',
    logout_1: 'Sair',
    to_remove_1: 'Retirar',
    log_reg_1: 'faça login Cadastre-se',
    sign_account_1: 'Faça login na sua conta',
    welcome_bet_1: 'Bem vindo ao diamante5',
    forget_pwd_1: 'Esqueça a senha?',
    to_enter_1: 'Entrar',
    not_member_1: 'Ainda não é membro?',
    reg_account_1: 'Registrar Conta',
    i_agree_1: 'Eu concordo',
    privacy_1: 'Com os termos e política de privacid',
    register_1: 'Registrar',
    verify_tip_1: 'Você receberá um código de 6 dígitos para verificar a seguir.',
    policy_1: 'Com os termos e politica de privacid',
    wdwal_rules_1: 'Adicionar carto bancário',
    wdwal_detail_1: 'Detalhes da retirada',
    quty_wdawn_1: 'Quantidade retirada',
    dos_totais_1: 'Fundos totais',
    min_wdwal_1: 'Retirada mínima',
    user_name_1: 'Nome do usuário',
    card_holder_1: 'Insira o nome do titular do cartao',
    cpf_code_1: 'Código CPF',
    e_cpf_code_1: 'Insira o seu código CPF',
    bank_code_1: 'Código bancário',
    e_bank_code_1: 'Por favor insira o código do banco',
    phone_number_1: 'Número de telefone',
    e_phone_num_1: 'Por favor insira o número de telefone',
    mail_1: 'Correspondência',
    e_email_1: 'Por favor introduza o seu e-mail',
    wdwal_fee_1: 'Taxa De Retirada',
    dept_method_1: 'Método De depósito',
    wdwal_status_1: 'Status De Retirada',
    pixpay_1: 'pixpay',
    pending_1: 'Pendente',
    mouy:'R$',
    porfavorelige:'por favor elige',
    Hall:'Salão',
    PGSlots:'Ranuras PG',
    competencia:' boasortepgé um excelente jogo de caça-níqueis online especialmente desenvolvido para os amantes de cassinos. Rege-se pelos princípios da concorrência leal e do controle legal local. Oferece aos jogadores com mais de 18 anos uma variedade de métodos de apostas diferentes e jogabilidade especial. Os jogadores só precisam escolher seu tipo de jogo de caça-níqueis preferido e, em seguida, fazer sua aposta, girar a máquina caça-níqueis para ter chances iguais de ganhar.',
    PIX:'PIX Os tipos',
    su:'O número da sua conta PIX',
    resu:'Digite novamente o número para contabilizar o PIX',
    oname:'ou me dê um nome',
    twok:'O número do cartão é inconsistente 2 vezes',
    tip1_1: 'sem dados',
    tip2_1: 'Seguro',
    tip3_1: 'Número de celular real',
    cashmony: 'retirada',
    min_recharge_money: 'O valor mínimo de recarga é',
    more_info: 'Ver Tudo',
    send_check: 'Não participe de promoções',
    send_coin_tip_1: 'O desconto no primeiro depósito pode render até 50% de presente grátis',
    send_coin_tip_2: 'Caso tenha interesse, recomende seus amigos e o bnus de convite pode ser de até 60',
    channel_tip: 'Pagamento da equipe do projeto.'
}