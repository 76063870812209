import request from './base'; //导入接口的前缀地址

//获取支付关信息
export function payInfo(data) {
	return request({
		url: "/pay/recharge",
		method: "POST",
		data: data
	})
}

//充值记录 member/rechargeList
export function rechargeList() {
	return request({
		url: "/member/rechargeList",
		method: "POST",
	})
}

//发起支付
export function displayPay(data) {
	return request({
		url: "/display",
		method: "POST",
		data: data
	})
} 

//获取提现记录
export function drawList(data) {
	return request({
		url: "/member/drawList",
		method: "POST",
		data: data
	})
} 

//发起提现的数据  shop/doDraw
export function doDraw(data) {
	return request({
		url: "/shop/doDraw",
		method: "POST",
		data: data
	})
}
