<template>
  <div class="dbox">
    <!-- <div class="left">
      <el-container>
        <CommonAside />
      </el-container>
    </div> -->
    <div class="right">
      <!-- <TopRes></TopRes> -->
      <div class="sf_box"></div>
      <div style="width: 100%; height: 50px; margin: 0 auto;">
        <router-link to="/gomoney"> <button
            style="width: 300px; height: 50px; background-color: brown; margin-left: 50%; border-radius: 20px; border: 1px solid brown; color: #fff; ">{{ $t('Rechargepresent') }}</button></router-link>
      </div>
      <el-container>
        <tabBar />
      </el-container>
    </div>
  </div>
</template>

<script>
// import CommonAside from '../../components/CommonAside'
// import TopRes from '../../components/TopRes.vue'
import tabBar from '../../components/tabBar.vue';
export default {
  components: {
    // CommonAside,
    // TopRes,
    tabBar
  }
}
</script>

<style scoped>
.sf_box {
  width: 80%;
  height: 650px;
  background-image: url(../../assets/bg_15.png);
  background-repeat: no-repeat;
  /* background-size: cover; */
  /* background-position: center; */
  background-repeat: repeat;
  /* 2. repeat-x 表示 x轴铺满 */
  background-repeat: repeat-x;
  /* 3. repeat-y 表示 y轴铺满 */
  background-size: 100% 650px;
  margin: 0 auto;
}
</style>
