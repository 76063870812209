<template>
  <div class="box">
    <!-- <TopRes></TopRes>
    <el-container>
      <CommonAside />
    </el-container> -->
    <div class="cz_top" 
      v-loading="loading>0" 
      :element-loading-text="loading==2 ? 'paying...' : 'loading...'" 
      element-loading-spinner="el-icon-loading">
      <div class="cz_tou">
        <div class="cz_zi">{{ $t('deposit_1') }}</div>
        <div class="cz_tz">
          <router-link to="/rechargerecord"> <img src="../../assets/Deposito (1).png" style="width: 278px; height: 48px;"
              alt=""></router-link>
        </div>
      </div>
      <div class="cz_shuru">
        <!-- <input type="text" placeholder="100"> -->
        <input type="number" v-model="coin" @input="inputChange">
        <!-- <div class="cz_yb">+5.00</div> -->
      </div>
      <div class="cz_sj">
        <div v-for="(item, idx) in list" 
          :key="item.id" 
          class="cz_gy" 
          :class="{ 'coin-actived': idx == selIdx }"
          @click="selectCoin(idx)">
          <div class="cz_ioop">{{ $t('mouy') }}{{ item.pay_view_coin }}</div>
          <div v-show="item.sendcoin>0" class="cz_y">+{{ item.sendcoin }}</div>
        </div>
      </div>
      <!-- <div class="cz_zi" style="margin-top: 30px;">{{$t('choose_pay_1')}}</div>
      <div class="cz_shuru">
        <input style="color: #fff;" type="text" :placeholder="$t('fast_pay_1')">
        <img src="../../assets/checked.png" style="width: 24px; height: 24px;" alt="">
      </div> -->
      <div class="channel-box">
        <span class="channel-tip">{{$t('channel_tip')}}</span>
        <div class="channels">
          <span class="channel-btn" 
            :class="{'channel-btn-actived':idx == channelIdx}" 
            v-for="(item, idx) in channels" 
            :key="idx" 
            @click="selectChannel(idx)">
            {{item.title}}
          </span>
        </div>
      </div>
      <div class="send-info">
        <p v-show="isNoSendCoin" class="tip-info">
          <span>1. {{$t('send_coin_tip_1')}}</span><br>
          <span>2. {{$t('send_coin_tip_2')}}</span>
        </p>
        <el-checkbox class="check-btn" v-model="isNoSendCoin">{{$t('send_check')}}</el-checkbox>
      </div>
      <div class="button">
        <img src="../../assets/btn1.png" alt="" @click="displayPayPage()">
      </div>
    </div>
    <el-container>
      <tabBar />
    </el-container>
  </div>
</template>
 
<script>
// import CommonAside from '../../components/CommonAside'
// import TopRes from '../../components/TopRes.vue'
import tabBar from '@/components/tabBar.vue';
import { payInfo, displayPay } from '@/api/pay.js'
export default {
  components: {
    // CommonAside,
    // TopRes,
    tabBar
  },

  data() {
    return {
      channels: [], // 渠道数据
      channelIdx: 0,
      noCoinlist: [], // 金额数据(无赠送)
      coinList: [], // 金额数据(有赠送)
      selIdx: 0,
      coin: 0,
      minCoin: 0,
      isNoSendCoin: false, // 是否不赠送金额(默认是有赠送)
      loading: 0,
    };
  },

  watch: {
    isNoSendCoin(val) {
      // console.log('ok==>111', val);
      this.inputChange();
    },
  },

  computed: {
    list() {
      return this.isNoSendCoin 
        ? (this.noCoinlist[this.channelIdx] || [])  
        : (this.coinList[this.channelIdx] || []);
    },
  },

  mounted() {
    this.getPayInfos();
  },

  methods: {
    getPayInfos() {
      this.loading = 1;
      payInfo().then((res) => {
        // console.log('res==>', res);
        this.loading = 0;
        if (res.code != 200) return;
        this.channels = res.data.channels;
        this.channels.forEach(item => {
          const arr1 = [];
          const arr2 = [];
          item.pages.forEach(itm => {
            if (itm.sendcoin > 0 || (itm.id == 42 && itm.pay_view_coin == 10 && itm.disrate == 0)) {
									arr2.push(itm)	
								} else {
									arr1.push(itm)
									console.log(arr2);
								};
          });
          this.noCoinlist.push(arr1);
          this.coinList.push(arr2);
        });
        if (this.channels.length > 0)
          this.minCoin = this.coin = this.channels[this.channelIdx].pages[0].pay_view_coin;
      });
    },

    displayPayPage() {
      if (this.coin < this.minCoin) {
        this.$message({
          message: this.$t('min_recharge_money') + ' ' + this.$t('mouy') + this.minCoin,
          type: 'error'
        });
        return;
      }
      if (this.selIdx == -1) return;
      this.loading = 2;
      displayPay({
        id: this.list[this.selIdx].id,
        act: 'post_pay',
        amount: this.coin,
      }).then((res) => {
        // console.log('res==>', res);
        this.loading = 0;
        if (res.code != 200) return;
        window.location.href = res.data.url;
      });
    },

    selectChannel(idx) {
      this.channelIdx = idx;
    },

    selectCoin(idx) {
      this.selIdx = idx;
      this.coin = this.list[idx].pay_view_coin;
    },

    inputChange(evt) {
        this.selIdx = this.findItemByCoin();
    },

    findItemByCoin() {
      if (!this.list || this.list.length == 0) return -1;
      for (let i = this.list.length - 1; i >= 0; i--) {
        if (this.list[i].pay_view_coin <= this.coin) return i;
      }
      return -1;
    },
  }
}
</script>
  
<style scoped>
.channel-box {
  width: 90%;
  display: block;
}

.channel-tip {
  display: inline-block;
  color: #fff;
  font-size: 18px;
}

.channels{
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.channel-btn {
  color: #fff;
  font-size: 16px;
  display: inline-block;
  width: 100px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  background-color: #ff3757;
  cursor: pointer;
}

.channel-btn.channel-btn-actived {
  background-color: #b04f00;
}

.send-info {
  width: 90%;
  color: #fff;
  font-size: 16px;
} 

.tip-info {
  line-height: 25px;
}

.send-info .check-btn ::v-deep .el-checkbox__input {
  transform: scale(1.2);
}

.cz_sj ::v-deep .el-loading-mask {
  background-color: transparent;
}

.coin-actived {
  background: hsla(0, 0%, 100%, .2);
}

.cz_top ::v-deep .el-loading-mask {
  background-color: transparent;
}

.cz_top {
  width: 80%;
  /* height: 903px; */
  /* height: 600px; */
  background-color: rgba(255, 255, 255, .1);
  /* margin-left: 17%;
  margin-top: 80px; */
  margin: 0 auto;
  margin-top: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 25px;
  padding: 40px 0 25px 0;
}

.cz_zi {
  width: 500px;
  /* height: 59px; */
  color: #fff;
  font-size: 28px;
  /* padding-top: 30px; */
  /* margin-left: 66px; */
}

.cz_tz img {
  /* margin-top: 30px; */
}

.cz_tou {
  /* width: 95%; */
  width: 90%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 30px; */
  /* padding-top: 40px; */
}

.cz_shuru {
  /* width: 91%; */
  width: 90%;
  height: 50px;
  /* margin: 0 auto; */
  border: 1px solid white;
  /* margin-top: 10px; */
  border-radius: 10px;
  /* position: sticky; */
  display: flex;
}

.cz_shuru input {
  /* width: 98%; */
  /* height: 30px; */
  width: 100%;
  height: 100%;
  /* margin: 0 auto; */
  background-color: rgba(255, 255, 255, .001);
  border: 0 solid white;
  line-height: 50px;
  /* margin-left: 1%;
  margin-top: 10px; */
  border-radius: 10px;
  color: #fff;
  outline: none;
  font-size: 20px;
  padding: 0 10px;
}

.cz_yb {
  width: 50px;
  height: 20px;
  background-color: red;
  color: #fff;
  border-radius: 8px;
  position: absolute;
  top: 0px;
  right: 0px;
}

.cz_sj {
  /* width: 92%; */
  width: 90%;
  min-height: 256px;
  /* height: 252px; */
  /* margin: 0 auto; */
  /* display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-row-gap: 35px;
  grid-column-gap: 80px;
  margin: 24px 0;
}

.cz_gy {
  /* width: 329px; */
  width: 100%;
  /* height: 60px; */
  display: flex;
  border: 1px solid white;
  border-radius: 15px;
  /* position: sticky; */
  position: relative;
  /* margin-top: 22px;
  margin-left: 10px; */
  cursor: pointer;
  overflow: hidden;
}

.cz_ioop {
  color: #fff;
  font-size: 26px;
  margin: 0 auto;
  line-height: 60px;
}

.cz_y {
  width: 60px;
  /* height: 20px; */
  height: 24px;
  background-color: red;
  color: #fff;
  border-radius: 10px;
  position: absolute;
  top: 0px;
  right: 0px;
  text-align: center;
}

.cz_shuru img {
  margin-top: 15px;
  margin-left: -10px;
}

.button {
  /* width: 93%; */
  width: 90%;
  /* height: 50px; */
  height: 72px;
  /* margin: 0 auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 40px; */
  cursor: pointer;
}

.button img {
  width: 400px;
  /* height: 72px; */
  height: 100%;
  margin: 0 auto;
}
</style>