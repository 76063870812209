<template>
  <div class="dbox">
    <!-- <div class="left">
      <el-container>
        <CommonAside />
      </el-container>
    </div> -->
    <div class="right">
      <!-- <TopRes></TopRes> -->
      <div class="yq_box">
        <img class="image" src="../../assets/telegramImg.png" />
        <button class="btn-box" @click="$router.push('/customer')">{{ $t('Jointhegroup') }}</button>
      </div>
      <el-container>
        <tabBar />
      </el-container>
    </div>
  </div>
</template>

<script>
// import CommonAside from "../../components/CommonAside";
// import TopRes from "../../components/TopRes.vue";
import tabBar from '../../components/tabBar.vue'
export default {
  components: {
    // CommonAside,
    // TopRes,
    tabBar
  },
};
</script>

<style scoped>
.yq_box {
  width: 80%;
  /* height: 600px; */
  /* background-image: url(../../assets/telegramImg.png);
  background-repeat: no-repeat; */
  /* background-size: cover; */
  /* background-position: center; */
  /* background-repeat: repeat; */
  /* 2. repeat-x 表示 x轴铺满 */
  /* background-repeat: repeat-x; */
  /* 3. repeat-y 表示 y轴铺满 */
  /* background-size: 100% 600px; */
  margin: 60px auto 60px auto;
  position: relative;
}

.yq_box .image {
  width: 100%;
  border-radius: 20px;
}

.btn-box {
  width: 350px;
  height: 70px;
  background-color: brown;
  /* margin-left: 35%; */
  border-radius: 10px;
  border: 1px solid rgb(105, 48, 22);
  color: #fff;
  font-size: 20px;
  /* margin-top: 570px;
  margin-bottom: 40px; */
  position:absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100px;
}
</style>
