<template>
  <div class="zff_box1">
    <div class="dll_box loading-box"
      v-loading="loading"
      element-loading-text="loading..."
      element-loading-spinner="el-icon-loading">
      <img src="../../assets/logo2.7b35e1e5.png" alt="" />
      <div class="zff_ziti">Bem-vindo ao bradescobet. Divirta-se!</div>
      <div class="dl_box">
        <div class="dl_zi">+55</div>
        <el-input class="num-input"
          type="number"
          v-model="phone1"
          placeholder="Tu nùmero de celular"></el-input>
      </div>
      <div class="dl_box" style="margin-top: 20px">
        <el-input size="small" placeholder="Please enter password" v-model="password" show-password
          type="password"></el-input>
      </div>
      <div class="dl_annf">
        <el-button class="dl_ann"
          :disabled="disabled"
          style=" background: linear-gradient( 333deg, #F4ADCD 0%, #FFFFFF 100%);
          color: #B00247;
          text-align: center;
          margin-top: 20px;
        " @click="goHome">{{ $t("to_enter_1") }}</el-button>
      </div>
      <div class="dl_xy">
        <div class="dl_po" style="color: #fff;">{{ $t("not_member_1") }}</div>
        <router-link to="/RegistrAtion" style="text-decoration: none;">
          <div class="dl_pi">{{ $t("reg_account_1") }}</div>
        </router-link>
      </div>
      <div class="dl_dx">
        <el-checkbox v-model="checked"></el-checkbox>
        <div style="margin-left: 10px;">{{ $t("i_agree_1") }}{{ $t("privacy_1") }}</div>
        <!-- <div>{{ $t("privacy_1") }}</div> -->
      </div>
    </div>
    <!-- <el-loading :visible="loading" text="loading">
    </el-loading> -->
  </div>
</template>

<script>
import { login, member } from '../../api/index.js'
import { EventBus } from '../../load/event-bus';
export default {
  data() {
    return {
      password: "",
      phone1: "",
      checked: false,
      disabled: true,
      loading: false,
    }
  },

  methods: {
    goHome() {
      this.loading = true;
      const config = {
        phone: this.phone1,
        password: this.password,
      };

      login(config)
        .then((response) => {
          this.loading = false;
          const token = response.data.token; // 请根据实际情况获取token字段
          // 将token存储到localStorage
          EventBus.$emit('loginSuccess', token);
          localStorage.setItem("token", token);
          this.Token = response.data.token;
          if (response.code == 200) {
            this.$router.replace('/'); // 跳转到指定路径并替换当前页面历史记录
            // EventBus.$emit('updateMenuSelectChange', '/index');
            // this.drawer = false;
            member(config).then((res) => {
              console.log(res);
              localStorage.setItem("userinfo", JSON.stringify(res.data));
              // 将对象转为 JSON 字符串
              // const jsonString = JSON.stringify(userObject);
            });
          }
        }).catch(err => {
          this.loading = false;
          console.log('err111==>', err);
        })
    },
  },

  watch: {
    checked(newCheckedValue) {
      console.log(newCheckedValue);
      // 当 checked 发生变化时触发
      if (newCheckedValue) {
        this.disabled = false;
        // 启用按钮
      } else {
        this.disabled = true; // 禁用按钮
      }
    },

    zc_box2() {
      (this.zc_box = false), (this.zc_xs = true);
    },
  },

  mounted() {
    if (localStorage.getItem("token")) {
      this.Token = localStorage.getItem("token");
    }
  },
}
</script>

<style scoped>
.num-input ::v-deep input::-webkit-outer-spin-button,
.num-input ::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.num-input ::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}

.loading-box ::v-deep .el-loading-mask {
  background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.zff_box1 {
  width: 100%;
  height: 1000px;
  background: #B00247;
  background-size: 100%;
}

.dll_box {
  /* width: 440px; */
  width: 450px;
  height: 600px;
  /* background-color: black; */
  margin: 0 auto;
}

.dll_box img {
  width: 160px;
  height: 50px;
  margin: 0 auto;
  margin-left: 130px;
  margin-top: 40px;
}

.zff_ziti {
  color: #a68574;
  text-align: center;
  font-size: 14px;
}

.dl_box {
  width: 400px;
  height: 50px;
  border: 1px solid rgb(97, 96, 96);
  margin: 0 auto;
  margin-top: 34px;
  display: flex;
  border-radius: 10px;
  padding: 0 10px;
}

.el-input {
  line-height: 48px;
  font-size: 20px;
}

.el-input ::v-deep input {
  /* line-height: 48px; */
  border: none;
  background-color: transparent;
  padding: 0 10px;
  color: #fff;
}

.el-input ::v-deep input::-webkit-input-placeholder {
  -webkit-text-fill-color: #888;
}

.dl_dx {
  width: 100%;
  height: 12px;
  margin: 0 auto;
  color: #fff;
  display: flex;
  margin-top: 30px;
}

.dl_zc {
  width: 100%;
  height: 30px;
  color: #fff;
  font-size: 14px;
  margin-top: 30px;
  text-align: center;
}

.iuytre {
  color: #fff;
  width: 300px;
  height: 50px;
  text-align: center;
  background-color: brown;
  border-radius: 15px;
  line-height: 50px;
  margin: 20px auto;
}

.dl_pi {
  color: #fff;
  text-align: center;
}

.dl_box input {
  background-color: black;
  border: 0;
  /* margin-top: 8px; */
  margin-left: 10px;
  outline: none;
  color: #fff;
  font-size: 20px;
}

.dl_zi {
  color: #fff;
  font-size: 24px;
  line-height: 50px;
}

.el-input--small .el-input__inner {
  margin-top: 10px !important;
}

.dl_annf {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.dl_ann {
  width: 400px;
  height: 50px;
  border: none;
  border-radius: 10px;
}
</style>
