<template>
    <div>
        <div style="height: 100vh;" class="dbox loading-box" v-loading="loading" element-loading-text="loading..."
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0)">
            <iframe :src="this.url" frameborder="0" width=100% height=100% marginwidth=0 marginheight=0 border="0"
                scrolling="yes"></iframe>
        </div>
    </div>
</template>

<script>

import { banner, index, games } from "../../api/index.js";

export default {
    data() {
        return {

            paydata: {

            },
            url: '',
            loading: false,

        }
    },
    methods: {
        gogame() {
            console.log(JSON.parse(this.$route.query.game));
            this.loading = true
            this.paydata = JSON.parse(this.$route.query.game)
            games(this.paydata).then((res) => {
                this.loading = false
                this.url = res.data.url
            })
        }

    },
    mounted() {
        this.gogame()
    }

}

</script>

<style scoped>
#iframeId {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}
</style>