
// 西班牙语言
export default {
    Firstcharge: "Primera carga",
    ReloadBonuses: 'Bonos de recarga',
    Becomeapartner: 'Convertirse en un compañero',
    joinus: 'Únete a nosotros',
    InvitationBonus: 'Bono de invitación',
    VIPIntroduction: 'Introducción VIP',
    Personalranking: 'Clasificación personal',
    Contactus: 'Correo',
    customerservice: 'atención al cliente',
    Rechargepresent: 'Regalo de recarga',
    Contactnow: 'Contacta ahora',
    Jointhegroup: 'Únete al grupo',
    InvitefriendsEarnmoneytogether: 'Invitar amigos Ganar dinero juntos',
    promote: 'Para promocionar la plataforma vitoriabet7, se lanzó un plan especial de recompensas por invitación, para que todos puedan ganar grandes premios en el juego inmersivo.',
    Howtoinvite: 'como invitar',
    Invitedaily: 'invitar diariamente',
    Invitationdetails: 'Detalles de la invitación',
    tellmore: 'Cuéntale a más amigos sobre esta excelente plataforma de juegos a través de la promoción social y gana mucho dinero al mismo tiempo con comisiones.',
    invitationlink: 'Haga clic en el botón para copiar el enlace de invitación.',
    copy: 'Copiar',
    registration: 'Invitación válida a los clientes de vitoriabet7 (los clientes deben rellenar el número de teléfono móvil para registrarse y completar la recarga), con una recompensa máxima de 60,00 reales. Al mismo tiempo, tenemos comisiones en el mostrador (comisiones considerables) basadas en el monto de las apuestas de los usuarios en la Plataforma de juegos. Las comisiones varían según el juego.',
    Forexample: 'Por ejemplo:',
    betcacau: 'Tom se registró como usuario válido de vitoriabet7 el 10 de abril de 2023. Después de ganar el Premio a la plataforma, también recomendó a un colega de la compañía. El colega completó el registro y recargó 50 reales, y Tom recibió una recompensa de 10.00 reales. A este tipo le gusta jugar. La apuesta total por tres días consecutivos. A lo largo de abril, Tom recibió un total de 51.089 dólares en Comisiones. Según la política de descuento de vitoriabet7, puede obtener una comisión adicional de $2670.17. Aunque el colega recomendó a su amigo a flurricao, según la política de descuento de vitoriabet7, a Tom también le gustó la política de descuento de apuestas relacionada con el amigo de su colega.',
    month: 'mes',
    today: 'hoy',
    week: 'semana',
    year: 'año',
    yesterday: 'ayer',
    Myagentdetails: 'Detalles de mi agente',
    Number: 'Número',
    Codevalue: 'Valor del código',
    Fullrecharge: 'Recarga completa',
    Initialcharge: 'Carga inicial',
    LevelAgent: 'Agente de nivel 1',
    Codevalue: 'Valor del código',
    Level2Agent: 'Agente de nivel 2',
    Level1Promotion: 'Promoción Nivel 1',
    Promotion: 'Promoción Nivel 2',
    alcum: 'alco',
    Fullbet: 'apuesta completa',
    Loadmore: 'Carga más',
    Privilege: 'Privilegio',
    Maximum: 'Límite máximo de retiro único:$ ',
    // withdrawals: 'Número de retiros por día:$',
    Betting: "Puntos de apuesta:",
    deposits: "Depósitos totales:",
    ranking: 'Clasificación de apuestas semanal',
    updated: '(Las clasificaciones se actualizan cada hora.)',
    subscription: 'Los diez mejores usuarios diarios se unen al grupo de suscripciones para recibir recompensas de clasificación',
    participate: 'Haga clic para participar',
    FortuneClassification: 'Clasificación de la fortuna',
    NoRanking: 'Sin clasificación',
    Ranking: 'Clasificación',
    id: "YO IBA",
    Textfone: 'Teléfono de texto',
    Currency: 'Moneda ($)',
    reward: 'Recibe tu recompensa',
    more: 'Carga más',
    Withdraw: 'Cantidad de retiro',
    funds: 'antecedentes totales:',
    withdrawals: 'el número máximo de retiros por día es ',
    amount: 'y el monto máximo de retiro es',
    Toremove: 'Retirar',
    deposit_1: 'Depósito',
    choose_pay_1: 'elige el método de pago',
    fast_pay_1: 'Canal de pago rápido 1',
    identifier_1: 'identificador',
    value_1: 'Valor',
    time_1: 'tiempo',
    next_level_1: 'Siguiente nivel',
    total_dep_1: 'Depósitos totales',
    withdrawal_1: 'Retiro',
    play_amount_1: 'Juega para desbloquear más cantidad extraíble',
    removable_1: 'se puede retirar',
    value_unlocked_1: 'Valor a ser desbloqueado',
    logout_1: 'Salir',
    to_remove_1: 'Para eliminar',
    log_reg_1: 'iniciar sesión Registrarse',
    sign_account_1: 'Iniciar sesión en su cuenta',
    welcome_bet_1: 'Bienvenido a vitoriabet7',
    forget_pwd_1: '¿Contraseña olvidada?',
    to_enter_1: 'Entrar',
    not_member_1: 'Aún no es miembro?',
    reg_account_1: 'Registrar Cuenta',
    i_agree_1: 'Estoy de acuerdo',
    privacy_1: 'Con términos y política de privacidad.',
    register_1: 'Registradora',
    verify_tip_1: 'Recibirás un código de 6 dígitos para verificar a continuación.',
    policy_1: 'Con términos y política de privacidad.',
    wdwal_rules_1: 'Añadir tarjeta bancaria >',
    wdwal_detail_1: 'Detalles de retiro',
    quty_wdawn_1: 'tipo de tarjeta',
    dos_totais_1: 'Fondos totales',
    min_wdwal_1: 'Retiro mínimo',
    user_name_1: 'Nombre de usuario',
    card_holder_1: 'Ingrese el nombre del titular de la tarjeta',
    cpf_code_1: 'código CPF',
    e_cpf_code_1: 'Introduce tu número de tarjeta',
    bank_code_1: 'número de tarjeta',
    e_bank_code_1: 'Por favor ingrese su código bancario',
    phone_number_1: 'Número de teléfono',
    e_phone_num_1: 'Por favor ingrese el número de teléfono',
    mail_1: 'Correspondencia',
    e_email_1: 'Por favor introduzca su correo electrónico',
    wdwal_fee_1: 'Cargo por retiro',
    dept_method_1: 'Método De depósito',
    wdwal_status_1: 'Método De depósito',
    pixpay_1: 'pixpago',
    pending_1: 'Pendiente',
    mouy:'$',
    competencia:'vitoriabet7 es un excelente juego de tragamonedas en línea especialmente diseñado para los amantes de los casinos. Se rige por los principios de competencia leal y control legal local. Ofrece a los jugadores mayores de 18 años una variedad de métodos de apuestas diferentes y una jugabilidad especial. Los jugadores sólo necesitan elegir su tipo de juego de tragamonedas preferido y luego hacer su apuesta y girar la máquina tragamonedas para tener las mismas posibilidades de ganar.',
    PIX:'PIX Os tipos',
    su:'Seu número de conta PIX',
    resu:'Redigite o número da conta PIX',
    oname:'O nome',
    twok:'El número de tarjeta es inconsistente 2 veces',
    tip1_1: 'sin datos',
    tip2_1: 'Seguro',
}