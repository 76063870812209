<template>
    <div class="box">
        <!-- <TopRes></TopRes>
        <el-container>
            <CommonAside />
        </el-container> -->
        <div class="jl_top">
            <div class="jl_return">
                <img src="../../assets/back.png" style="width: 26px; height: 29px;" alt="">
                <div class="jl_zi">{{ $t('deposit_1') }}</div>
            </div>
            <div class="conten_table">
                <table>
                    <thead>
                        <tr>
                            <td>{{ $t('identifier_1') }}</td>
                            <td>{{ $t('value_1') }}</td>
                            <!-- <td> bônus </td>
                            <td> Método de depósito </td>
                            <td> Estado do depósito </td> -->
                            <td>{{ $t('time_1') }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- <tr class="jl_bg">
                            <td style="display: flex; align-items:center; justify-content: center;">
                                <span>4AD3019730278ECF</span>
                                <img src="../../assets/img8.png" style="width: 20px; height: 20px;" alt="">
                            </td>
                            <td>R$30.00</td>
                            <td>R$1.50</td>
                            <td>pixpay</td>
                            <td>Pending</td>
                            <td>13.11 10:06</td>
                        </tr> -->

                        <tr v-for="item in rech_list" :key="item.id" class="jl_bg">
                            <td style="display: flex; align-items:center; justify-content: center;">
                                <span>{{ item.orderid }}</span>
                                <img src="../../assets/img8.png" style="width: 20px; height: 20px;" alt="">
                            </td>
                            <td>{{ $t('mouy') }}{{ item.real_amount }}</td>
                            <!-- <td>R$1.50</td>
                            <td>pixpay</td>
                            <td>Pending</td> -->
                            <td>{{ item.format_create_time }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <el-container>
            <tabBar />
        </el-container>
    </div>
</template>

<script>
// import CommonAside from '../../components/CommonAside'
// import TopRes from '../../components/TopRes.vue'
import tabBar from '@/components/tabBar.vue';
import { rechargeList } from '@/api/pay.js'
export default {
    components: {
        // CommonAside,
        // TopRes,
        tabBar
    },

    data() {
        return {
            rech_list: [],
        };
    },

    mounted() {
        this.getRechargeList();
    },

    methods: {
        // 获取充值记录
        getRechargeList() {
            rechargeList().then((res) => {
                // console.log('res==>', res);
                if (!res) return;
                this.$message({
                    message: 'success',
                    type: res.code == 200 ? 'success' : 'error',
                });
                if (res.code != 200) return;
                this.rech_list = res.data.data;
            });
        },
    }
}
</script>

<style scoped>
.jl_top {
    width: 80%;
    height: 300px;
    /* margin-left: 17%; */
    background-color: #000;
    margin: 0 auto;
    margin-top: 70px;
    border-radius: 15px;
}

.jl_return {
    display: flex;
    max-width: 82vw;
    min-width: 1000px;
    margin-left: 40px;
    padding-top: 20px;
}

.jl_zi {
    color: #fff;
    line-height: 30px;
}

.conten_table {
    width: 95%;
    margin: 20px auto;
    background-color: rgba(225, 255, 255, .1);
}

.conten_table table {
    width: 100%;
    border-collapse: collapse;

}

.conten_table td {
    border: 1px solid #fff;
    text-align: center;
    line-height: 30px;
    color: #fff;
}

thead dt {
    border-radius: 20px 20px 0px 0px;
}
</style>