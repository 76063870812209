import Vue from 'vue'
import VueRouter from 'vue-router'

import login from '../views/LoginSrc/LoginSrc.vue'
import index from '../views/IndexSrc/IndexSrc.vue'
import VipSrc from '../views/VipSrc/VipSrc.vue'
import topup from '../views/TopUp/TopUp.vue'
import agent from '../views/Agent/AgentRes.vue'
import telegram from '../views/TeleGram/TeleGram.vue'
import invite from '../views/InviteSrc/InviteSrc.vue'
import homepage from '../views/HomePage/HomePage.vue'
import gomoney from '../views/GoMoney/GoMoney.vue'
import rechargerecord from '../views/RechargeRecord/RechargeRecord.vue'
import rabkcard from '../views/BankCard/BankCard.vue'
import withdramoney from '../views/WithdraMoney/WithdraMoney.vue'
import message from '../views/MessAge/MessAge.vue'
import content from '../views/ConTent/ConTent.vue'
import withdraw from '../views/WthdRaw/WthdRaw.vue'
import customer from '../views/CustOmer/CustOmer.vue'
import frame from '@/views/IndexFrame/IndexFrame.vue'
import register from '@/views/RegiSter/RegiSter.vue'
import RegistrAtion from '@/views/RegistrAtion/RegistrAtion.vue'
import MorePage from '@/views/HomePage/MorePage.vue'
import WebView from '@/views/HomePage/WebView.vue'
import giftcard from  '@/views/GiftCard/GiftCard.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/', 
    redirect: '/index',
  },
  {
    path: '/index', 
    name: 'index',
    component: frame,
    children: [
      {
        path: '/', // 保留一个根路径
        name: 'homepage',
        component: homepage,
        meta: {},
      },
      {
        path: '/login',
        name: 'login',
        component: login,
        meta: {reqAuth: true},
      },
      {
        path: '/home', 
        name: 'home',
        component: index,
        meta: {},
      },
      {
        path: '/VipSrc',
        name: 'VipSrc',
        component: VipSrc,
        meta: {reqAuth: true},
      },
      {
        path: '/topup',
        name: 'topup',
        component: topup,
        meta: {},
      },
      {
        path: '/agent',
        name: 'agent',
        component: agent,
        meta: {},
      },
      {
        path: '/telegram',
        name: 'telegram',
        component: telegram,
        meta: {},
      },
      {
        path: '/invite',
        name: 'invite',
        component: invite,
        meta: {reqAuth: true},
      },
      {
        path: '/gomoney',
        name: 'gomoney',
        component: gomoney,
        meta: {},
      },
      {
        path: '/rechargerecord',
        name: 'rechargerecord',
        component: rechargerecord,
        meta: {},
      },
      {
        path: '/rabkcard',
        name: 'rabkcard',
        component: rabkcard,
        meta: {},
      },
      {
        path: '/withdramoney',
        name: 'withdramoney',
        component: withdramoney,
        meta: {},
      },
      {
        path: '/message',
        name: 'message',
        component: message,
        meta: {reqAuth: true},
      },
      {
        path: '/content',
        name: 'content',
        component: content,
        meta: {},
      },
      {
        path: '/withdraw',
        name: 'withdraw',
        component: withdraw,
        meta: {},
      },
      {
        path: '/customer',
        name: 'customer',
        component: customer,
        meta: {reqAuth: true},
      },
      {
        path: '/register',
        name: 'register',
        component: register,
        meta: {},
      },
      {
        path: '/RegistrAtion',
        name: 'RegistrAtion',
        component: RegistrAtion,
        meta: {},
      },
      {
        path: '/MorePage',
        name: 'MorePage',
        component: MorePage,
        meta: {},
      },
    ],
  },
  {
    path:'/giftcard',
    name:'giftcard',
    component:giftcard,
    meta:{},
  },
  {
    path:'/WebView',
    name:'WebView',
    component:WebView,
    meta:{},
  }
]

const router = new VueRouter({
  mode: 'history', //'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 获取存储在localStorage或其他地方的Token值
  const token = localStorage.getItem('token');
  
  if (to.meta.reqAuth && !token) {
      // Token不存在且该页面需要认证时，重定向到登录页面
      next('/register');
  } else {
      // Token有效或者该页面无需认证时，正常进行页面跳转
      next();
  }
});

export default router
