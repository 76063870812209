<template>
  <div class="dbox">
    <!-- <div class="left">
      <el-container>
        <CommonAside />
      </el-container>
    </div> -->
    <div class="right">
      <!-- <TopRes></TopRes> -->
      <div class="xjyh_box" v-loading="loading" element-loading-text="loading..."
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0)">
        <!-- <div class="yut_top">
          <div class="xjyh_top">
            <div>{{ $t("wdwal_rules_1") }}</div>
            <router-link to="/withdraw">
              <img src="../../assets/help.png" alt="" />
            </router-link>
          </div>
          <router-link to="/withdramoney">
            <div class="xjyh_top">
              <div>{{ $t("wdwal_detail_1") }}</div>
              <img src="../../assets/icon2 (1).png" alt="" /></div
          ></router-link>
        </div> -->
        <!-- <el-select v-model="value" placeholder="por favor elige" @change="handleChange">
          <el-option v-for="item in options" :key="item.orderid" :label="item.label" :value="item.value">
          </el-option>
        </el-select> -->
        <div class="zi">
          <div class="zf_oi">{{ $t("PIX") }}</div>
        </div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-select v-model="formInline.region" placeholder="CPF" @change="aa">
            <el-option label="CPF" value="1"></el-option>
            <el-option label="CNPJ" value="2"></el-option>
            <el-option label="PHONE" value="3"></el-option>
            <el-option label="EMAIL" value="4"></el-option>
          </el-select>
        </el-form>
        <div class="zi">
          <div class="zf_oi">{{ $t("su") }}</div>
        </div>
        <div class="shuruk">
          <input type="text" v-model="account" :placeholder="$t('su')" />
        </div>
        <div class="zi">
          <div class="zf_oi">{{ $t("resu") }}</div>
        </div>
        <div class="shuruk">
          <input type="text" v-model="reaccount" :placeholder="$t('resu')" />
        </div>
        <div class="zi">
          <div class="zf_oi">{{ $t("oname") }}</div>
        </div>
        <div class="shuruk">
          <input type="text" v-model="username" :placeholder="$t('oname')" />
        </div>

        <div class="zi">
          <div class="zf_oi">{{ $t("tip3_1") }}</div>
        </div>
        <div class="shuruk">
          <span class="quhao">+55</span>
          <input type="number" v-model="phone" :placeholder="$t('tip3_1')" />
        </div>
        <!-- <div class="zi">
          <div class="zf_oi">{{ $t("mail_1") }}</div>
        </div>
        <div class="shuruk">
          <input type="text" v-model="email" :placeholder="$t('e_email_1')" />
        </div> -->
        <div class="anniu" @click="submit">{{ $t("to_remove_1") }}</div>
      </div>
      <tabBar />
    </div>
  </div>
</template>

<script>
// import CommonAside from "../../components/CommonAside";
// import TopRes from "../../components/TopRes.vue";
import tabBar from "../../components/tabBar.vue";
import { doBind, getbankcode } from "../../api/index.js";
export default {
  data() {
    return {
      account: "",
      reaccount: "",
      username: "",
      pix_type: 1,
      phone: '',
      formInline: {
        user: "",
        region: "",
      },
      options: [
        // {
        //     value: '选项1',
        //     label: '黄金糕'
        //   }, {
        //     value: '选项2',
        //     label: '双皮奶',
        //   }, {
        //     value: '选项3',
        //     label: '蚵仔煎'
        //   }, {
        //     value: '选项4',
        //     label: '龙须面'
        //   }, {
        //     value: '选项5',
        //     label: '北京烤鸭'
        //   }
      ],
      value: '',
      loading: false,
    };
  },

  methods: {
    aa(value) {
      console.log(value, 111)
      this.pix_type = value;
    },

    submit() {
      if (this.account == this.reaccount) {
        this.loading = true;
        const data = {
          account: this.account,
          reaccount: this.reaccount,
          username: this.username,
          pix_type: this.pix_type,
          phone: this.phone,
        };
        // console.log(data,1111)
        doBind(data).then((res) => {
          //console.log('ok==>111', res);
          this.loading = false;
          if (!res) return;
          if (res.code == 200) {
            this.$message({
              message: 'success',
              type: 'success'
            })
            this.$router.go(-1);
          }
        });
      } else {
        // Message.error(this.$t('twok'));
        this.$message({
          showClose: true,
          message: this.$t('twok'),
          type: 'error'
        })
      }
    },

    iuyt() {
      getbankcode().then((res1) => {
        //  this.options=res1.data.list
        // 将res1.data.list转换为options数组
        this.options = res1.data.list.map((item) => ({
          value: item.bankcode,
          label: item.bankname,
        }));
      });
    },
  },

  components: {
    // CommonAside,
    // TopRes,
    tabBar,
  },

  mounted() {
    // this.iuyt();
  },
};
</script>

<style scoped>
.quhao {
  display: inline-block;
  width: 100px;
  color: #fff;
  font-size: 16px;
  line-height: 32px;
}

.xjyh_box {
  width: 80%;
  /* margin-left: 17%; */
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0 auto;
  margin-top: 70px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.xjyh_top {
  width: 260px;
  height: 48px;
  color: #fff;
  font-size: 20px;
  display: flex;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  margin-left: 30px;
  align-items: center;
}

.xjyh_top div {
  line-height: 48px;
  margin: 0 auto;
  text-decoration: none;
}

.yut_top {
  width: 90%;
  height: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.xjyh_top img {
  width: 40px;
  height: 40px;
}

.zi {
  width: 90%;
  height: 20px;
  margin: 0 auto;
  display: flex;
  color: #fff;
  margin-top: 20px;
  justify-content: space-between;
}

.zf_ioop {
  display: flex;
}

.shuruk {
  display: flex;
  width: 90%;
  height: 30px;
  margin: 0 auto;
  border: 1px solid #fff;
  margin-top: 10px;
  border-radius: 5px;
  overflow: hidden;
}

a {
  text-decoration: none;
}

.shuruk input {
  height: 30px;
  border: none;
  font-size: 14px;
  outline: none;
  color: #fff;
  background-color: rgb(26, 26, 26);
  padding-left: 10px;
  width: 100%;
}

.anniu {
  width: 300px;
  height: 60px;
  margin: 20px auto;
  color: #fff;
  background-color: brown;
  line-height: 60px;
  text-align: center;
  font-size: 20px;
  border-radius: 15px;
  cursor: pointer;
}

.el-select>.el-input {
  margin-left: 70px !important;
  background-color: rgb(39, 39, 39) !important;
}

.xjyh_box ::v-deep .el-select {
  width: 90%;
  margin: 20px 0 0 65px;
  background: #000
}

.xjyh_box ::v-deep .el-input--small .el-input__inner {
  background: #000
}
</style>