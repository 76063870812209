import request from './base'; //导入接口的前缀地址

//登录接口
export function login(data) {
	return request({
		url: "/login",
		method: "POST",
		data: data
	})
}
//注册
export function register(data) {
	return request({
		url: "/register",
		method: "POST",
		data: data
	})
}
//退出登录
export function logout() {
	return request({
		url: "/logout",
		method: "POST",
	})
}
//获取用户信息
export function member() {
	return request({
		url: "/member/index",
		method: "POST",
	})
}
//vip列表 member/vip
export function vip() {
	return request({
		url: "/member/vip",
		method: "POST",
	})
}
//获取用户余额 member/getmoney
export function getmoney() {
	return request({
		url: "/member/getmoney",
		method: "POST",
	})
}
//首页数据——用户排行 /index
export function index() {
	return request({
		url: "/index",
		method: "POST",
	})
}
//获取首页轮播图数据  banner
export function banner() {
	return request({
		url: "/index",
		method: "POST",
	})
}
//获取分享数据 share
export function share() {
	return request({
		url: "/share",
		method: "POST",
	})
}
//获取邀请数据 invites
export function invites(data) {
	return request({
		url: "/share/invites",
		method: "POST",
		data: data,
	})
}
//站内信-列表  member/emailList
export function emailList() {
	return request({
		url: "/member/emailList",
		method: "POST",
	})
}
//站内信-详情  member/emailInfo
export function emailInfo(data) {
	return request({
		url: "/member/emailInfo",
		method: "POST",
		data: data
	})
}
//领取站内信奖励
export function getAttach(data) {
	return request({
		url: "/member/getAttach",
		method: "POST",
		data: data
	})
}
//绑定银行卡  shop/doBind
export function doBind(data) {
	return request({
		url: "/shop/doBind",
		method: "POST",
		data: data
	})
}
// 银行卡列表  shop/guide
export function guide() {
	return request({
		url: "/shop/guide",
		method: "POST",
	})
}
//提现限制数据
export function drawconfig() {
	return request({
		url: "/shop/drawconfig",
		method: "POST",
	})
}
//游戏跳转接口
export function games(data) {
	return request({
		url: "/display",
		method: "POST",
		data: data
	})
}
//客服 member/customerService
export function customerService() {
	return request({
		url: "/member/customerService",
		method: "POST",
	})
}
//银行卡编码列表 getbankcode
export function getbankcode() {
	return request({
		url: "/getbankcode",
		method: "POST",
	})
}

//页面获取礼品卡
export function getCard(data){
	return request({
		url: "/giftCard",
		method: "POST",
		data:data
	})
}

//用户获取礼品卡
export function usergetCard(data){
	return request({
		url: "/receiveCard",
		method: "POST",
		data:data
	})
}